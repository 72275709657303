"use client";
import { setAuthToken, useMeMutation } from "@/store";
import { getFromLocale, removeFromLocale, setToLocale } from "@/utils";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormOverlay } from "./Global/FormOverlay";

export const Auth = () => {
  const dispatch = useDispatch();
  const token = getFromLocale();
  const [
    checkToken,
    { data: userData, isSuccess: isSuccessUserData, error: apiError, isError },
  ] = useMeMutation();

  const { isLoading } = useSelector((state) => state.loading);

  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    const access_token = searchParams.get("access_token");
    const status = searchParams.get("status");

    if (access_token) {
      setToLocale({ value: access_token });
      router.push("/");
    }

    if (status === "logout") {
      removeFromLocale();
      router.push("/sign-in");
    }
  }, [router, searchParams]);

  useEffect(() => {
    const fetchUser = async () => {
      await checkToken();
    };
    if (token) {
      fetchUser();
    }
  }, [checkToken]);

  useEffect(() => {
    if (isSuccessUserData && userData?.body) {
      dispatch(setAuthToken(getFromLocale()));
    }
  }, [userData, isSuccessUserData, dispatch]);

  useEffect(() => {
    if (apiError && isError) {
      removeFromLocale();
      dispatch(setAuthToken(""));
    }
  }, [dispatch, apiError, isError]);

  return <>{isLoading && <FormOverlay />}</>;
};
