"use client";
import { memo, useEffect } from "react";
import { BtnGroup } from "./BtnGroup";

export const Navbar = memo(function Navbar({ locale, default_currency, referer, data, currency }) {
  useEffect(() => {
    document.cookie = `lang=${locale}; path=/; max-age=${30 * 24 * 60 * 60
      }`;

    document.cookie = `currency=${currency}; path=/; max-age=${30 * 24 * 60 * 60
      }`;

  }, [])
  return (
    <header className="sticky top-0 z-999 bg-white">
      <BtnGroup data={data} referer={referer} currency={currency} default_currency={default_currency} locale={locale} />
    </header>
  );
});
