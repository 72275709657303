import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/websiteca/builder.websiteca.com/node_modules/next-intl/dist/src/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/websiteca/builder.websiteca.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/websiteca/builder.websiteca.com/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["About"] */ "/home/websiteca/builder.websiteca.com/src/components/About.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Alert"] */ "/home/websiteca/builder.websiteca.com/src/components/Alert.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Auth"] */ "/home/websiteca/builder.websiteca.com/src/components/Auth.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/websiteca/builder.websiteca.com/src/components/Navbar/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/home/websiteca/builder.websiteca.com/src/components/Navbar/Navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/websiteca/builder.websiteca.com/src/redux/provider.js");
