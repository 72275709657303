'use client'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { setAlertData } from "@/store";

export const Alert = () => {
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState();
  const { message, status, toastId } = useSelector((state) => state.alert);

  useEffect(() => {
    setAlertMessage(message);
  }, [message]);

  const hideAlert = () => {
    dispatch(setAlertData({ message: "", status: "", toastId: null }));
  };

  useEffect(() => {
    if (status && alertMessage) {
      const id = toast[status](alertMessage, {
        onClose() {
          hideAlert();
        },
      });

      dispatch(setAlertData({ toastId: id }));
    }
  }, [status, alertMessage]);

  const closeToast = () => {
    if (toastId) {
      toast.dismiss(toastId);
      hideAlert();
    }
  };

  return <ToastContainer className="z-9999" autoClose={3000} onClose={closeToast} />;
};
